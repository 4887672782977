<template>
  <section>
    <FormulateForm
      ref="productPromotionForm"
      name="productPromotionForm"
    >
      <b-card-actions
        action-collapse
        :title="`${$t('Dados da Promoção')}${promotion.id ? ` #${promotion.id}` : ''}`"
        :show-loading="loading"
      >
        <b-row>
          <b-col md="9">
            <b-row>
              <b-col md="4">
                <FormulateInput
                  id="name"
                  v-model="promotion.name"
                  :is-edit="false"
                  type="text"
                  :label="$t('Nome')"
                  validation="required"
                  class="required"
                />
              </b-col>
              <b-col
                v-if="canAllowAllStores"
                md="3"
              >
                <FormulateInput
                  v-model="promotion.allowAllStores"
                  type="switch"
                  :label="$t('Habilitar todas as lojas?')"
                  :instruction="
                    $t(
                      'Quando habilitado, a promoção será liberada para todas as lojas cadastradas no sistema'
                    )
                  "
                />
              </b-col>
              <b-col
                v-if="!promotion.allowAllStores"
                md="5"
              >
                <e-store-combo
                  id="stores"
                  v-model="promotion.storesSelected"
                  type="vue-select"
                  class="required"
                  :multiple="true"
                  :label="$t('Lojas participantes')"
                  validation="required"
                />
              </b-col>
              <b-col md="4">
                <FormulateInput
                  id="period"
                  v-model="promotion.period"
                  type="date-range-picker"
                  :label="$t('Período')"
                  class="required"
                  validation="required"
                />
              </b-col>
              <b-col md="8">
                <FormulateInput
                  id="description"
                  v-model="promotion.description"
                  type="textarea"
                  :label="$t('Descrição')"
                  rows="5"
                />
              </b-col>
            </b-row>
          </b-col>

          <b-col md="3">
            <b-row>
              <b-col md="12">
                <b-tabs fill>
                  <b-tab
                    active
                    :title="$t('Quadrada')"
                  >
                    <FormulateInput
                      id="image-square-local"
                      v-model="imageSquareLocal"
                      type="uploader"
                      deletable="true"
                      max-files="1"
                      :meta="false"
                      :disabled="loading"
                    />
                  </b-tab>
                  <b-tab :title="$t('Wide')">
                    <FormulateInput
                      id="image-wide-local"
                      v-model="imageWideLocal"
                      type="uploader"
                      deletable="true"
                      max-files="1"
                      :meta="false"
                      :disabled="loading"
                    />
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-actions>

      <b-card-actions
        action-collapse
        :title="$t('Produtos')"
        :show-loading="loading"
      >
        <b-row>
          <b-col class="d-flex justify-content-end">
            <e-button
              variant="primary"
              :text="$t('Adicionar Produto')"
              @click="onShowSidebar()"
            />
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-table
              ref="productTable"
              show-empty
              responsive
              class="bordered"
              :fields="fields"
              :items="getPromotionItems"
              :empty-text="$t('Nenhum produto adicionado')"
            >
              <template #cell(item)="row">
                {{ row.item.item.label }}
              </template>

              <template #cell(imageSquare)="row">
                <b-img-lazy
                  :src="getImageSquare(row.item)"
                  rounded
                  center
                  width="90px"
                  style="object-fit: contain; max-height: 150px"
                />
              </template>

              <template #cell(sku)="row">
                <e-product-router-link
                  :sku-id="row.item.sku.id"
                  :product-id="row.item.sku.productId"
                  :product-name="row.item.sku.name"
                  :ean="row.item.sku.ean"
                />
              </template>

              <template #cell(action)="row">
                <e-grid-actions
                  :show-update="true"
                  :show-delete="true"
                  @update="onShowSidebar(row)"
                  @delete="onDeletePromotionItem(row)"
                />
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card-actions>
    </FormulateForm>

    <e-crud-actions
      :busy="loading"
      @save="onSavePromotion"
      @cancel="onCancel"
    />

    <promotion-item-sidebar
      ref="productPromotionItemSidebar"
      :promotion-items="getPromotionItems"
    />
  </section>
</template>

<script>
import { BRow, BCol, BTable, BImgLazy, BTab, BTabs } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { formulateHelper, discountTypes, uploader } from '@/mixins'
import {
  ECrudActions,
  EGridActions,
  EStoreCombo,
  EButton,
  EProductRouterLink,
} from '@/views/components'
import NoImage from '@/assets/images/noimage.png'
import { isUserAllowedAllStores } from '@/auth/utils'
import PromotionItemSidebar from './PromotionItemSidebar.vue'

export default {
  name: 'PromotionMaintain',
  components: {
    BCardActions,
    BRow,
    BCol,
    EButton,
    BTable,
    BImgLazy,
    BTab,
    BTabs,
    ECrudActions,
    EStoreCombo,
    EGridActions,
    PromotionItemSidebar,
    EProductRouterLink,
  },

  mixins: [formulateHelper, discountTypes, uploader],

  data() {
    return {
      loading: false,
      imageSquareLocal: [],
      imageWideLocal: [],
      noImage: NoImage,
      range: {
        startDate: null,
        endDate: null,
      },
    }
  },

  computed: {
    ...mapState('app', ['stores']),
    ...mapState('pages/sale/promotion/promotionMaintain', ['promotion']),
    ...mapGetters('pages/sale/promotion/promotionMaintain', ['getPromotionItems']),

    isEdit() {
      return !!this.$route.params.id
    },

    canAllowAllStores() {
      return isUserAllowedAllStores()
    },

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Imagem'),
          key: 'imageSquare',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '150px' },
        },
        {
          label: this.$t('Produto'),
          thClass: 'text-center',
          tdClass: 'text-left',
          key: 'sku',
          formatter: value => value.name,
        },
        {
          label: this.$t('Quantidade'),
          key: 'quantity',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: value => value || this.$t('Ilimitado'),
        },
        {
          label: this.$t('Disponível'),
          key: 'quantitySold',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: (value, index, item) => this.promotionItemAvailable(item),
        },
        {
          label: this.$t('Preço De'),
          key: 'priceFrom',
          tdClass: 'text-right',
          thStyle: { width: '150px' },
          formatter: value => this.$options.filters.currency(value),
        },
        {
          label: this.$t('Preço Por'),
          key: 'price',
          tdClass: 'text-right',
          thStyle: { width: '150px' },
          formatter: value => this.$options.filters.currency(value),
        },
      ]
    },
  },

  async mounted() {
    if (this.isEdit) {
      try {
        this.loading = true
        await this.stLoadPromotionById(this.$route.params.id)

        this.imageSquareLocal = this.promotion.imageSquare
          ? [this.getFileInstance(this.promotion.imageSquare)]
          : []
        this.imageWideLocal = this.promotion.imageWide
          ? [this.getFileInstance(this.promotion.imageWide)]
          : []
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loading = false
      }
    }
  },

  methods: {
    ...mapActions('pages/sale/promotion/promotionMaintain', {
      stRemovePromotionItem: 'removePromotionItem',
      stCleanState: 'cleanState',
      stLoadPromotionById: 'loadPromotionById',
    }),

    getImageSquare(item) {
      return item.sku?.imageSquare || this.noImage
    },

    promotionItemAvailable(item) {
      if (!item.quantity) {
        return this.$t('Ilimitado')
      }
      if ((item.quantitySold || 0) >= item.quantity) {
        return this.$t('Esgotado')
      }
      return item.quantity - (item.quantitySold || 0)
    },

    onShowSidebar(row) {
      this.$refs.productPromotionItemSidebar.show(row)
    },

    async onSavePromotion() {
      this.$refs.productPromotionForm.showErrors()
      if (this.$refs.productPromotionForm.hasErrors) {
        this.showInvalidDataMessage({})
        return
      }

      try {
        this.loading = true
        const images = await this.uploadImages()
        const [imageWide, imageSquare] = images

        const { items, stores } = this.promotion
        const storesUpdated = [...stores]

        // Mark the stores to deletion
        storesUpdated.forEach(store => {
          // Disable eqeqeq because the storeId on storesSelected is string
          // eslint-disable-next-line eqeqeq
          if (!this.promotion.storesSelected.find(storeId => store.store?.id == storeId)) {
            // eslint-disable-next-line no-param-reassign
            store.isDeleted = true
          }
        })

        // Process added stores
        const newStores = this.promotion.storesSelected.filter(
          // Disable eqeqeq because the storeId on storesSelected is string
          // eslint-disable-next-line eqeqeq
          storeId => !this.promotion.stores.find(store => store.store?.id == storeId)
        )
        if (newStores.length) {
          storesUpdated.push(
            ...this.stores
              .filter(store => newStores.indexOf(String(store.id)) >= 0)
              .map(store => ({
                id: 0,
                storeId: store.id,
              }))
          )
        }

        const body = {
          ...this.promotion,
          startDate: this.promotion.period.startDate,
          endDate: this.promotion.period.endDate,
          imageWide: imageWide?.url,
          imageSquare: imageSquare?.url,
          items: items.map(item => ({
            ...item,
            skuId: item.sku.id,
          })),
          stores: storesUpdated.map(store => ({
            id: store.id,
            storeId: store.store?.id || store.storeId,
            isDeleted: store.isDeleted,
          })),
        }
        await this.$http({
          url: '/api/promotions',
          method: this.isEdit ? 'PUT' : 'POST',
          data: body,
        })

        this.showSuccess({
          message: this.$t(`${this.isEdit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
        })
        this.$router.push({ name: 'promotion-list' })
        // this.$formulate.reset('productPromotionForm')
        this.$formulate.resetValidation('productPromotionForm')
        this.stCleanState()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loading = false
      }
    },

    async uploadImages() {
      let resultWide = null
      let resultSquare = null
      const result = await this.upload({
        files: [...this.imageWideLocal, ...this.imageSquareLocal],
        destinationPath: 'images/promotions',
      })
      if (this.imageWideLocal.length) {
        ;[resultWide] = result
      }
      if (this.imageSquareLocal.length) {
        ;[resultSquare] = result.slice(result.length > 1 ? 1 : 0)
      }
      const nullResult = { url: null }
      return [resultWide || nullResult, resultSquare || nullResult]
    },

    async onDeletePromotionItem(row) {
      const confirm = await this.confirm()
      if (confirm) {
        const { localId, id } = row.item
        this.stRemovePromotionItem(localId || id)
      }
    },

    onCancel() {
      this.stCleanState()
      this.$router.push({ name: 'promotion-list' })
    },

    formatDiscount(value, type) {
      return type === this.discountTypesEnum.PERCENTAGE
        ? this.$options.filters.percentage(value)
        : this.$options.filters.currency(value)
    },
  },
}
</script>
