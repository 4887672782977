var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-sidebar", {
    attrs: {
      id: "sidebar-form-product-promotion",
      title: _vm.isPromotionItemUpdate
        ? _vm.$t("Editar item da Promoção")
        : _vm.$t("Novo item da Promoção"),
      show: _vm.showSidebar,
      fetching: _vm.fetching,
      saving: _vm.saving,
      width: "500px",
    },
    on: { save: _vm.onAddPromotionItem, hidden: _vm.onHideSidebar },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "FormulateForm",
              {
                ref: "promotionSidebarForm",
                attrs: { name: "promotionSidebarForm" },
              },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _vm.isPromotionItemUpdate
                          ? _c("FormulateInput", {
                              attrs: {
                                id: "promotion_item_sidebar-sku-label",
                                type: "label",
                                label: _vm.$t("Produto"),
                              },
                              model: {
                                value: _vm.promotionForm.sku.name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.promotionForm.sku,
                                    "name",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "promotionForm.sku.name",
                              },
                            })
                          : _vm._e(),
                        _c("e-search-sku", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isPromotionItemUpdate,
                              expression: "!isPromotionItemUpdate",
                            },
                          ],
                          attrs: {
                            id: "promotion_item_sidebar-sku",
                            "value-is-object": "",
                            "only-active": "",
                          },
                          model: {
                            value: _vm.promotionForm.sku,
                            callback: function ($$v) {
                              _vm.$set(_vm.promotionForm, "sku", $$v)
                            },
                            expression: "promotionForm.sku",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "4" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "promotion_item_sidebar-quantity",
                            "is-edit": false,
                            type: "number",
                            label: _vm.$t("Quantidade"),
                            instructions: [
                              {
                                text: _vm.$t(
                                  "Quantidade que será disponibilizada para a promoção"
                                ),
                              },
                              {
                                text: _vm.$t(
                                  "Se quantidade for ilimitada, deixe em branco"
                                ),
                              },
                            ],
                          },
                          model: {
                            value: _vm.promotionForm.quantity,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.promotionForm,
                                "quantity",
                                _vm._n($$v)
                              )
                            },
                            expression: "promotionForm.quantity",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "4" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "price-from",
                            type: "text-number",
                            currency: "R$",
                            precision: 3,
                            label: _vm.$t("Preço de"),
                            validation: "required",
                          },
                          model: {
                            value: _vm.promotionForm.priceFrom,
                            callback: function ($$v) {
                              _vm.$set(_vm.promotionForm, "priceFrom", $$v)
                            },
                            expression: "promotionForm.priceFrom",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "4" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "price",
                            type: "text-number",
                            currency: "R$",
                            precision: 3,
                            label: _vm.$t("Preço por"),
                            validation: "required",
                          },
                          model: {
                            value: _vm.promotionForm.price,
                            callback: function ($$v) {
                              _vm.$set(_vm.promotionForm, "price", $$v)
                            },
                            expression: "promotionForm.price",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }