<template>
  <e-sidebar
    id="sidebar-form-product-promotion"
    :title="isPromotionItemUpdate ? $t('Editar item da Promoção') : $t('Novo item da Promoção')"
    :show="showSidebar"
    :fetching="fetching"
    :saving="saving"
    width="500px"
    @save="onAddPromotionItem"
    @hidden="onHideSidebar"
  >
    <template #content>
      <FormulateForm
        ref="promotionSidebarForm"
        name="promotionSidebarForm"
      >
        <b-row>
          <b-col>
            <FormulateInput
              v-if="isPromotionItemUpdate"
              id="promotion_item_sidebar-sku-label"
              v-model.number="promotionForm.sku.name"
              type="label"
              :label="$t('Produto')"
            />
            <e-search-sku
              v-show="!isPromotionItemUpdate"
              id="promotion_item_sidebar-sku"
              v-model="promotionForm.sku"
              value-is-object
              only-active
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="4">
            <FormulateInput
              id="promotion_item_sidebar-quantity"
              v-model.number="promotionForm.quantity"
              :is-edit="false"
              type="number"
              :label="$t('Quantidade')"
              :instructions="[
                { text: $t('Quantidade que será disponibilizada para a promoção') },
                { text: $t('Se quantidade for ilimitada, deixe em branco') },
              ]"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="price-from"
              v-model="promotionForm.priceFrom"
              type="text-number"
              currency="R$"
              :precision="3"
              :label="$t('Preço de')"
              class="required"
              validation="required"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="price"
              v-model="promotionForm.price"
              type="text-number"
              currency="R$"
              :precision="3"
              :label="$t('Preço por')"
              class="required"
              validation="required"
            />
          </b-col>
        </b-row>
      </FormulateForm>
    </template>
  </e-sidebar>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ESidebar from '@/views/components/ESidebar.vue'
import { discountTypes } from '@/mixins'
import { mapActions } from 'vuex'
import ESearchSku from '@/views/components/inputs/ESearchSku.vue'
import { getInitialPromotionItemForm } from '@/store/pages/sale/promotion/promotion-maintain'

export default {
  components: { BRow, BCol, ESidebar, ESearchSku },

  mixins: [discountTypes],

  props: {
    promotionItems: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isPromotionItemUpdate: false,
      promotionItemIndex: null,
      showSidebar: false,
      fetching: false,
      edit: false,
      saving: false,
      promotionForm: getInitialPromotionItemForm(),
    }
  },

  computed: {
    isDiscountPercent() {
      return this.promotionForm.discountType === this.discountTypesEnum.PERCENTAGE
    },

    getDiscountSymbol() {
      return this.isDiscountPercent ? this.$t('%') : this.$t('R$')
    },
  },

  methods: {
    ...mapActions('pages/sale/promotion/promotionMaintain', {
      stAddPromotionItem: 'addPromotionItem',
      stUpdatePromotionItem: 'updatePromotionItem',
    }),

    show(row) {
      this.cleanSidebar()
      this.showSidebar = true

      if (row) {
        this.promotionForm = { ...row.item }
        this.promotionItemIndex = row.index
        this.isPromotionItemUpdate = true
      }
    },

    onHideSidebar() {
      this.showSidebar = false
      this.cleanSidebar()
    },

    cleanSidebar() {
      this.isPromotionItemUpdate = false
      this.promotionItemIndex = null
      this.promotionForm = getInitialPromotionItemForm()
    },

    async onAddPromotionItem() {
      this.$refs.promotionSidebarForm.showErrors()
      if (this.$refs.promotionSidebarForm.hasErrors) {
        this.showInvalidDataMessage()
        return
      }
      if (this.isPromotionItemUpdate) {
        await this.stUpdatePromotionItem({ promotionItem: this.promotionForm })
      } else {
        await this.stAddPromotionItem(this.promotionForm)
      }

      this.onHideSidebar()
    },
  },
}
</script>

<style></style>
