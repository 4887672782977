var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "FormulateForm",
        {
          ref: "productPromotionForm",
          attrs: { name: "productPromotionForm" },
        },
        [
          _c(
            "b-card-actions",
            {
              attrs: {
                "action-collapse": "",
                title:
                  "" +
                  _vm.$t("Dados da Promoção") +
                  (_vm.promotion.id ? " #" + _vm.promotion.id : ""),
                "show-loading": _vm.loading,
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "9" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "4" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "name",
                                  "is-edit": false,
                                  type: "text",
                                  label: _vm.$t("Nome"),
                                  validation: "required",
                                },
                                model: {
                                  value: _vm.promotion.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.promotion, "name", $$v)
                                  },
                                  expression: "promotion.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.canAllowAllStores
                            ? _c(
                                "b-col",
                                { attrs: { md: "3" } },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      type: "switch",
                                      label: _vm.$t(
                                        "Habilitar todas as lojas?"
                                      ),
                                      instruction: _vm.$t(
                                        "Quando habilitado, a promoção será liberada para todas as lojas cadastradas no sistema"
                                      ),
                                    },
                                    model: {
                                      value: _vm.promotion.allowAllStores,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.promotion,
                                          "allowAllStores",
                                          $$v
                                        )
                                      },
                                      expression: "promotion.allowAllStores",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.promotion.allowAllStores
                            ? _c(
                                "b-col",
                                { attrs: { md: "5" } },
                                [
                                  _c("e-store-combo", {
                                    staticClass: "required",
                                    attrs: {
                                      id: "stores",
                                      type: "vue-select",
                                      multiple: true,
                                      label: _vm.$t("Lojas participantes"),
                                      validation: "required",
                                    },
                                    model: {
                                      value: _vm.promotion.storesSelected,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.promotion,
                                          "storesSelected",
                                          $$v
                                        )
                                      },
                                      expression: "promotion.storesSelected",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-col",
                            { attrs: { md: "4" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "period",
                                  type: "date-range-picker",
                                  label: _vm.$t("Período"),
                                  validation: "required",
                                },
                                model: {
                                  value: _vm.promotion.period,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.promotion, "period", $$v)
                                  },
                                  expression: "promotion.period",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "8" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "description",
                                  type: "textarea",
                                  label: _vm.$t("Descrição"),
                                  rows: "5",
                                },
                                model: {
                                  value: _vm.promotion.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.promotion, "description", $$v)
                                  },
                                  expression: "promotion.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "b-tabs",
                                { attrs: { fill: "" } },
                                [
                                  _c(
                                    "b-tab",
                                    {
                                      attrs: {
                                        active: "",
                                        title: _vm.$t("Quadrada"),
                                      },
                                    },
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          id: "image-square-local",
                                          type: "uploader",
                                          deletable: "true",
                                          "max-files": "1",
                                          meta: false,
                                          disabled: _vm.loading,
                                        },
                                        model: {
                                          value: _vm.imageSquareLocal,
                                          callback: function ($$v) {
                                            _vm.imageSquareLocal = $$v
                                          },
                                          expression: "imageSquareLocal",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-tab",
                                    { attrs: { title: _vm.$t("Wide") } },
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          id: "image-wide-local",
                                          type: "uploader",
                                          deletable: "true",
                                          "max-files": "1",
                                          meta: false,
                                          disabled: _vm.loading,
                                        },
                                        model: {
                                          value: _vm.imageWideLocal,
                                          callback: function ($$v) {
                                            _vm.imageWideLocal = $$v
                                          },
                                          expression: "imageWideLocal",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-card-actions",
            {
              attrs: {
                "action-collapse": "",
                title: _vm.$t("Produtos"),
                "show-loading": _vm.loading,
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c("e-button", {
                        attrs: {
                          variant: "primary",
                          text: _vm.$t("Adicionar Produto"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onShowSidebar()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-col",
                    [
                      _c("b-table", {
                        ref: "productTable",
                        staticClass: "bordered",
                        attrs: {
                          "show-empty": "",
                          responsive: "",
                          fields: _vm.fields,
                          items: _vm.getPromotionItems,
                          "empty-text": _vm.$t("Nenhum produto adicionado"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "cell(item)",
                            fn: function (row) {
                              return [
                                _vm._v(" " + _vm._s(row.item.item.label) + " "),
                              ]
                            },
                          },
                          {
                            key: "cell(imageSquare)",
                            fn: function (row) {
                              return [
                                _c("b-img-lazy", {
                                  staticStyle: {
                                    "object-fit": "contain",
                                    "max-height": "150px",
                                  },
                                  attrs: {
                                    src: _vm.getImageSquare(row.item),
                                    rounded: "",
                                    center: "",
                                    width: "90px",
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "cell(sku)",
                            fn: function (row) {
                              return [
                                _c("e-product-router-link", {
                                  attrs: {
                                    "sku-id": row.item.sku.id,
                                    "product-id": row.item.sku.productId,
                                    "product-name": row.item.sku.name,
                                    ean: row.item.sku.ean,
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "cell(action)",
                            fn: function (row) {
                              return [
                                _c("e-grid-actions", {
                                  attrs: {
                                    "show-update": true,
                                    "show-delete": true,
                                  },
                                  on: {
                                    update: function ($event) {
                                      return _vm.onShowSidebar(row)
                                    },
                                    delete: function ($event) {
                                      return _vm.onDeletePromotionItem(row)
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("e-crud-actions", {
        attrs: { busy: _vm.loading },
        on: { save: _vm.onSavePromotion, cancel: _vm.onCancel },
      }),
      _c("promotion-item-sidebar", {
        ref: "productPromotionItemSidebar",
        attrs: { "promotion-items": _vm.getPromotionItems },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }